import { useState } from "react";
import "./contactus.css";

function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    location: "",
    city: "",
    comment: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form Data Submitted:", formData);
    alert("Form submitted successfully!");
    // Clear form after submission
    setFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      location: "",
      city: "",
      comment: "",
    });
  };
  return (
    <>
      <div className="row g-0 contactUsPageBg">
        <div className="col-lg-6 col-md-12">
          <div className="row m-4 align-items-center">
            <div className="col-12 d-flex flex-column">
              <span className="font-face-Thasadith contactHedTxt">
                Contact Us
              </span>
              <span className="font-face-OpenSans contactDecTxt">
                We offer specialized Embedded Systems Development for diverse
                applications. Our team excels in microcontroller programming,
                firmware development, and embedded software solutions. Whether
                your focus is on IoT devices or real-time systems...
              </span>
              <form
                className="row g-0 font-face-OpenSans my-3"
                onSubmit={handleSubmit}
              >
                <div className="col-6 my-2">
                  <div className="contFldBg">
                    <input
                      type="text"
                      className="form-control contFldInput"
                      placeholder="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg">
                    <input
                      type="text"
                      className="form-control contFldInput"
                      placeholder="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg">
                    <input
                      type="tel"
                      className="form-control contFldInput"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg">
                    <input
                      type="email"
                      className="form-control contFldInput"
                      placeholder="Email ID"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg">
                    <input
                      type="text"
                      className="form-control contFldInput"
                      placeholder="Location"
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg">
                    <input
                      type="text"
                      className="form-control contFldInput"
                      placeholder="City"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 my-2">
                  <span className="contFldSpan">How can we help you?</span>
                  <textarea
                    className="form-control contCommnetFldBg"
                    placeholder="Write your comment..."
                    rows="4"
                    name="comment"
                    value={formData.comment}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <div className="col-12 d-flex justify-content-center my-3">
                  <button type="submit" className="contUsBtnSpan">
                    CONTACT US
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center align-items-center">
          <div className="contactDetailsCard">
            <p className="contactDetail">
              <img
                className="conntIcon"
                src="./images/contactus/i_loca.png"
                alt="Connect Mail Icon"
              ></img>
              Kochar Globe, 6th Floor, Smartworks, Guindy, Chennai
            </p>
            <p className="contactDetail">
              <img
                className="conntIcon"
                src="./images/contactus/i_phone.png"
                alt="Connect Mail Icon"
              ></img>
              9840854724 | 9952292211
            </p>
            <p className="contactDetail">
              <img
                className="conntIcon"
                src="./images/contactus/i_mail.png"
                alt="Connect Mail Icon"
              ></img>
              <p> essn@quantumpulse.ltd | info@quantumpulse.ltd</p>
            </p>
            <p className="contactDetail">
            <img
                className="conntIcon"
                src="./images/contactus/i_mail.png"
                alt="Connect Mail Icon"
              ></img>www.quantumpulse.ltd
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
