import "./esdm_industry.css";

function EdmsIndustry() {
  return (
    <>
      <div className="row g-0 pt-5">
        <div className="col-12 d-flex justify-content-center">
          <p className="font-face-Thasadith esdmHeadTxt">
            Electronics System Design and Manufacturing (ESDM) Industry
          </p>
        </div>
        <div className="row g-0 d-flex justify-content-center text-center py-3">
          <div className="col-8 font-face-OpenSans esdmThumdescTxt">
            India has been importing huge quantities of electronic products
            since decades. In fact, import of electronic products accounts for
            the second highest import bill. Multiple reasons like lack of
            infrastructure, technical know-how, cost competitiveness, etc., were
            responsible for low domestic manufacturing. However, there has been
            a paradigm shift where decades of hardship faced by electronic
            manufacturing companies has finally been converted to significant
            opportunities. Lots of factors have converged in the recent past
            that we are now standing at an inflection point.
          </div>
        </div>
      </div>
      <div className="row g-0 pt-4">
        <div className="col-12 d-flex justify-content-center">
          <div className="esdmThumMainDiv p-4">
            <img
              src="./images/design_services/DS_ESDM_1.jpg"
              alt="Design Service ESDM"
              className="srcimg"
            />
            <div className="font-face-Thasadith esdmThumHedTxt mt-4">
              UNDERSTANDING ELECTRONICS SYSTEM DESIGN AND MANUFACTURING (ESDM)
            </div>
            <p className="mt-4 font-face-OpenSans esdmThumdescTxt">
              ESDM involves a process of conceptualization, design, prototyping,
              manufacturing, testing, and providing after-market services for
              electronic systems and components
            </p>
          </div>
          <div className="esdmThumMainDiv p-4">
            <img
              src="./images/design_services/DS_ESDM_2.jpg"
              alt="Design Service ESDM"
              className="srcimg"
            />
            <div className="mt-2 font-face-Thasadith esdmThumHedTxt mt-4 mt-4 mt-4">
              VALUE CHAIN ANALYSIS OF ESDM MARKET
            </div>
            <p className="mt-4 font-face-OpenSans esdmThumdescTxt">
              We offer specialized Embedded Systems Development for diverse
              applications. Our team excels in microcontroller programming,
              firmware development, and embedded software solutions. Whether
              your focus is on IoT devices or real-time systems...
            </p>
          </div>
          <div className="esdmThumMainDiv p-4">
            <img
              src="./images/design_services/DS_ESDM_3.jpg"
              alt="Design Service ESDM"
              className="srcimg"
            />
            <div className="mt-2 font-face-Thasadith esdmThumHedTxt mt-4 mt-4">
              INDUSTRY WISE SEGMENTATION & GROWTH OPPORTUNITIES
            </div>
            <p className="mt-4 font-face-OpenSans esdmThumdescTxt">
              We provide rapid prototyping services that allow you to test and
              refine your ideas before moving to full-scale production. Using 3D
              modeling, mechanical simulation, and functional testing...
            </p>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-12 d-flex justify-content-center">
          <div className="esdmThumMainDiv p-4">
            <img
              src="./images/design_services/DS_ESDM_4.jpg"
              alt="Design Service ESDM"
              className="srcimg"
            />
            <div className="mt-2 font-face-Thasadith esdmThumHedTxt mt-4 mt-4">
              PRODUCT/SERVICE IN ESDM MARKET
            </div>
            <p className="mt-4 font-face-OpenSans esdmThumdescTxt">
              Our PCB Design Services offer full-cycle development, including
              schematic design, PCB layout, and prototyping. We handle
              high-speed PCB design, multi-layer board creation, and power
              system optimization to ensure superior functionality...
            </p>
          </div>
          <div className="esdmThumMainDiv p-4">
            <img
              src="./images/design_services/DS_ESDM_5.jpg"
              alt="Design Service ESDM"
              className="srcimg"
            />
            <div className="mt-2 font-face-Thasadith esdmThumHedTxt mt-4 mt-4">
              GROWTH DRIVERS - WHY IS THE RIGHT TIME FOR INDIA?
            </div>
            <p className="mt-4 font-face-OpenSans esdmThumdescTxt">
              We offer specialized Embedded Systems Development for diverse
              applications. Our team excels in microcontroller programming,
              firmware development, and embedded software solutions. Whether
              your focus is on IoT devices or real-time systems...
            </p>
          </div>
          <div className="esdmThumMainDiv p-4">
            <img
              src="./images/design_services/DS_ESDM_6.jpg"
              alt="Design Service ESDM"
              className="srcimg"
            />
            <div className="mt-2 font-face-Thasadith esdmThumHedTxt mt-4 mt-4">
              INDUSTRY PLAYERS
            </div>
            <p className="mt-4 font-face-OpenSans esdmThumdescTxt">
              We provide rapid prototyping services that allow you to test and
              refine your ideas before moving to full-scale production. Using 3D
              modeling, mechanical simulation, and functional testing...
            </p>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-12 d-flex justify-content-center">
          <div className="esdmThumMainDiv p-4">
            <img
              src="./images/design_services/DS_ESDM_7.jpg"
              alt="Design Service ESDM"
              className="srcimg"
            />
            <div className="mt-2 font-face-Thasadith esdmThumHedTxt mt-4 mt-4">
              KEY MONITORABLE FACTORS
            </div>
            <p className="mt-4 font-face-OpenSans esdmThumdescTxt">
              Our PCB Design Services offer full-cycle development, including
              schematic design, PCB layout, and prototyping. We handle
              high-speed PCB design, multi-layer board creation, and power
              system optimization to ensure superior functionality...
            </p>
          </div>
          <div className="esdmThumMainDiv p-4">
            <div className="esdmImgBg_dummy"></div>
            <div className="mt-2 font-face-Thasadith esdmThumHedTxt mt-4"></div>
            <p className="mt-4 font-face-OpenSans esdmThumdescTxt"></p>
          </div>
          <div className="esdmThumMainDiv p-4">
            <div className="esdmImgBg_dummy"></div>
            <div className="mt-2 font-face-Thasadith esdmThumHedTxt mt-4"></div>
            <p className="mt-4 font-face-OpenSans esdmThumdescTxt"></p>
          </div>
        </div>
      </div>
    </>
  );
}
export default EdmsIndustry;
