import "./desgin_service.css";
import EdmsIndustry from "./esdm_industry/esdm_industry";
import SupportServices from "./support_services/support_service";

function DesignServices() {
  return (
    <>
      <div className="row g-0">
        <div className="col-12">
          <div className="d-block position-relative">
            <img
              className="homeImg"
              src="./images/design_services/design_service.jpg"
              alt="Design Service"
            />
            <div className="imageTxtBgMain position-absolute d-flex justify-content-center">
              <div className="imageTxtBg position-absolute"></div>
              <div className="d-flex flex-column">
                <div className="text-center">
                  <span className="font-face-BebasNeueRegular imgHeadTxt1 position-relative">
                    DESIGN
                  </span>
                  <span className="font-face-BebasNeueRegular imgHeadTxt2 ps-3 position-relative">
                    SERVICES
                  </span>
                </div>
                <div className="font-face-OpenSans imgDescTxt position-relative">
                  Concept to Customer Content to appear here. Slide 6 content in
                  company ppt.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SupportServices />
      <EdmsIndustry />
    </>
  );
}

export default DesignServices;
