import "./quntam_plus.css";

function QuntamPlus() {
  return (
    <>
      <div className="row g-0 position-relative">
        <div className="col-12 ">
          <img
            className="qunatmImg"
            alt="QuntamPlusImg"
            src="./images/home/quntam_plus.png"
          ></img>
          <div className="row quntamBg g-0">
            <div className="col-6  m-0 p-0 d-flex">
              {/* <div className="p-5 mx-5"></div> */}
            </div>
            <div className="col-1  m-0 p-0"></div>
          </div>
          <div className="row g-0 position-absolute quntamTxtRow pt-4">
            <div className="col-6 d-flex justify-content-end">
              <div className="position-relative">
                <div className="imgTxtBg"></div>
                <div className="d-flex font-face-BebasNeueRegular position-relative imgTxtContTop">
                  <div className="d-flex flex-column text-end imgTxtPosition pt-3">
                    <span className="imgTxt  ps-4  pe-2 lh-1">WHY</span>
                    <span className="imgTxt  ps-4  pe-2 lh-1">CHOOSE</span>
                  </div>
                  <span className="QueMarkTxt  font-face-BebasNeueRegular imgQuesPosition lh-1 pt-2">
                    ?
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="plusMainDiv ">
                <div className="plusHedMainDiv">
                  <div className="d-flex flex-column font-face-BebasNeueRegular plusMoveLeft position-relative">
                    <span className=" plusHedingLine1Txt">Quantum Pulse</span>
                    <span className=" plusHedingLine2Txt position-relative">
                      Technologies
                    </span>
                  </div>
                </div>
                <div className="my-4 font-face-OpenSans plusDescTxt d-flex  homePagemargin  plusDescTxtTop ">
                <div className="text"> 
                   <span className="my-2 d-flex text">
                     
                    Our Permanent Placement service at Quantum Pulse
                    Technologies is designed to help businesses build a strong
                    core team for long-term success. We manage the entire
                    recruitment process, from sourcing and screening to
                    interviewing and negotiating employment offers...
                    
                  </span> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuntamPlus;
